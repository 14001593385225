import clsx from "clsx";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";

const FeatureBox = ({
  title,
  description,
  Icon,
  pro,
  highlighted,
}: {
  title: string;
  description?: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  pro?: string;
  highlighted?: boolean;
}) => (
  <div className="flex h-full flex-col">
    <div
      className={clsx(
        highlighted
          ? "border-orange-500"
          : "border-slate-300 dark:border-slate-700",
        "relative flex h-full flex-col gap-y-2 rounded-lg border py-3 px-3 text-left sm:py-4 sm:text-center" // Updated this line
      )}
    >
      <div className="absolute top-0 left-1/2 hidden -translate-y-1/2 -translate-x-1/2 bg-white px-4 dark:bg-gray-800 sm:block">
        <Icon className="h-5 w-5 text-orange-600" />
      </div>
      <div className="flex items-start gap-2 sm:items-center sm:justify-center">
        <div className="text-base font-semibold text-orange-500">
          <Icon className="mr-2 inline-block h-5 w-5 text-orange-600 sm:hidden" />
          {title}
        </div>
      </div>
      {description && (
        <p className="flex-shrink flex-grow text-sm text-slate-500 dark:text-slate-300">
          {description}
        </p>
      )}
      {pro && (
        <div className="flex flex-grow flex-col items-start justify-center sm:items-center md:mt-2">
          <div className="rounded-md py-1 px-0 text-left text-slate-600 dark:text-slate-200 sm:px-2 sm:py-3 sm:text-center">
            <div className="text-sm font-medium leading-4 sm:font-semibold">
              <ArrowRightCircleIcon className="-mt-0.5 mr-1 inline-block h-4 w-4 shrink-0 text-orange-500 sm:h-5 sm:w-5" />
              <span>{pro}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default FeatureBox;
