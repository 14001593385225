import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  FolderIcon,
  UserIcon,
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRouter } from "next/router";
import Link from "next/link";
import { useTheme } from "next-themes";
import { useKey, useMedia } from "react-use";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import { Disclosure, Menu, Transition } from "@headlessui/react";

import Logo from "@components/shared/Logo";
import LinkPeek from "@components/shared/LinkPeek";
import UpgradeModal from "@components/app/Upgrade/UpgradeModal";
import UpgradeButton from "@components/app/Upgrade/UpgradeButton";
import OnboardingTrialModal from "@components/app/OnboardingTrialModal";
import collectAnalyticsEvent from "@components/analytics/collectAnalyticsEvent";
import MenuLink from "@components/shared/MenuLink";
import Toaster from "@components/shared/Toaster";
import ConnectedModal from "@components/shared/ConnectedModal";
import ContentFeedbackModal from "@components/app/ContentFeedbackModal";
import Button from "@components/shared/Button";
import SearchPalette from "@components/app/SearchPalette";
import Annotation from "@components/shared/Annotation";
import { isMac } from "@utils/detectOS";
import useStore from "@hooks/useStore";
import { useMainHeight } from "@hooks/useMainHeight";
import { auth } from "@utils/firebase/firebase-initAuth";
import SearchButton from "./SearchButton";

export interface HeaderNavigationItem {
  name: string;
  href: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  newTab?: boolean;
  highlighted?: boolean;
}

const navigation: HeaderNavigationItem[] = [
  {
    name: "Start",
    href: "/start",
  },
  {
    name: "Lernen",
    href: "/lernen",
  },
  {
    name: "Nachschlagen",
    href: "/nachschlagen",
  },
  {
    name: "Ratgeber",
    href: "/ratgeber",
  },
];

const homepageNavigation: HeaderNavigationItem[] = [
  {
    name: "Vorteile",
    href: "/#vorteile",
  },
  {
    name: "Funktionen",
    href: "/#funktionen",
  },
  {
    name: "Inhalte",
    href: "/#inhalte",
  },
  {
    name: "Preise",
    href: "/preise",
  },
  {
    name: "Einzelunterricht",
    href: "https://www.jurahilfe.de",
    newTab: true,
  },
];

const userNavigation = [
  { name: "Einstellungen", href: "/profile" },
  { name: "divider" },
  {
    name: "🚀 Feedback Formular",
    href: "https://tally.so/r/mJq9WX",
    highlighted: true,
    newTab: true,
  },
  { name: "divider" },
  {
    name: "Datenschutzerklärung",
    href: "https://www.jurahilfe.de/privacy",
    newTab: true,
  },
  {
    name: "Nutzungsbedingungen",
    href: "https://www.jurahilfe.de/terms",
    newTab: true,
  },
  {
    name: "Impressum",
    href: "https://www.jurahilfe.de/impressum",
    newTab: true,
  },
  { name: "divider" },

  // { name: "Einstellungen", href: "#" },
  // { name: "Ausloggen", href: "#" },
];

const adminNavigation = [
  {
    name: "Admin-Dashboard",
    href: "/admin/dashboard",
    icon: AdjustmentsHorizontalIcon,
  },
  // {
  //   name: "Users",
  //   href: "/admin/users",
  //   icon: UserIcon,
  // },
  // { name: "Team", href: "/admin/team", icon: UserCircleIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ThemeSwitcher = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <button
      type="button"
      className={clsx("btn-text-default", "mr-3 rounded-full p-1")}
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
    >
      <span className="sr-only">Theme wechseln</span>
      {theme === "dark" ? (
        <MoonIcon className="h-6 w-6" aria-hidden="true" />
      ) : (
        <SunIcon className="h-6 w-6" aria-hidden="true" />
      )}
    </button>
  );
};

const shouldHideChatWidget = (route: string, onboardingDismissed: boolean) => {
  return (
    route.startsWith("/lernen") ||
    route.startsWith("/nachschlagen") ||
    route.startsWith("/ratgeber") ||
    !onboardingDismissed
  );
};

function NavigationContainer({ children }: { children: React.ReactNode }) {
  const isLg = useMedia("(min-width: 1024px)", true);
  const router = useRouter();
  const mainHeight = useMainHeight();
  const macDetected = isMac();

  const { user, sidebarCollapsed, set, sidebarOpen } = useStore((state) => ({
    user: state.user,
    set: state.set,
    sidebarCollapsed: state.sidebarCollapsed,
    sidebarOpen: state.sidebarOpen,
  }));

  const hideChatWidget = shouldHideChatWidget(
    router.pathname,
    user?.settings?.onboarding?.onboardingDismissed
  );

  // Add this useEffect to apply the class to the body
  useEffect(() => {
    if (hideChatWidget) {
      document.body.classList.add("hide-chat-widget");
    } else {
      document.body.classList.remove("hide-chat-widget");
    }
  }, [hideChatWidget, user?.settings?.onboarding?.onboardingDismissed]);

  // Add listener to CMD + P to toggle search
  useKey(
    (e) => (e.key === "k" && e.metaKey) || (e.key === "k" && e.ctrlKey),
    (e) => {
      if (!user) return;
      e.preventDefault();
      e.stopPropagation();
      set((state) => {
        state.searchOpen = !state.searchOpen;
      });
    }
  );

  // Get ref to the main element
  const mainRef = useRef<HTMLDivElement>(null);
  // Reset scroll position on route change
  useEffect(() => {
    if (mainRef.current) {
      console.log("scrolling to top");
      mainRef.current.scrollTop = 0;
    }
    // Listen to changes on asPath to also get triggered by dynamic URLS (otherwise use .pathname)
  }, [router.asPath]);

  const showSearch = () => {
    set((state) => {
      state.searchOpen = true;
    });
  };

  return (
    <>
      <div className={`min-h-full`}>
        <Disclosure
          as="nav"
          className={clsx(
            "navbar",
            "sticky top-0 z-40 border-slate-200 bg-slate-50 dark:bg-gray-800",
            // Hide shadow if small screen on /study page
            router.pathname.startsWith("/lernen")
              ? "border-none shadow-none sm:border-b sm:shadow-sm"
              : "border-b shadow-sm",
            !user
              ? "dark:border-gray-700 dark:bg-gray-900"
              : "dark:border-transparent"
          )}
        >
          {({ open, close }) => (
            <>
              <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    {/* Collapse Sidebar Icon */}
                    {(router.pathname.startsWith("/lernen") ||
                      // Check if pathName starts with "/nachschlagen"
                      router.pathname.startsWith("/nachschlagen") ||
                      router.pathname.startsWith("/ratgeber") ||
                      router.pathname == "/wiederholen") && (
                      <div
                        className={clsx(
                          isLg && !sidebarCollapsed ? "hidden" : "block",
                          "transform transition",
                          !sidebarOpen
                            ? "translate-x-0 opacity-100"
                            : "-translate-x-20 opacity-0"
                        )}
                      >
                        <button
                          type="button"
                          className={clsx(
                            "-ml-2 mr-3 inline-flex h-10 w-10 items-center justify-center rounded-md bg-opacity-80 md:-ml-3 lg:-ml-5",
                            "btn-text-default"
                          )}
                          onClick={() => {
                            set((state) => {
                              state.sidebarOpen = !sidebarOpen;
                            });
                          }}
                        >
                          <span className="sr-only">Sidebar öffnen</span>
                          <FolderIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    )}
                    <div className="flex-shrink-0">
                      <Link
                        href={!user ? "/" : "/start"}
                        className="focus-ring flex items-center rounded-md"
                        onClick={() =>
                          collectAnalyticsEvent({ eventName: "click-logo" })
                        }
                      >
                        <Logo />
                      </Link>
                    </div>
                    <div className={clsx(isLg ? "block" : "hidden")}>
                      {user && router.pathname !== "/" ? (
                        <div className="ml-10 flex items-center space-x-3">
                          {navigation.map((item) => (
                            <MenuLink
                              key={item.name}
                              href={item.href}
                              newTab={item.newTab}
                              isActive={router.pathname.startsWith(item.href)}
                            >
                              {item.name}
                            </MenuLink>
                          ))}
                          {user?.claims?.admin &&
                            adminNavigation.map((item) => (
                              <MenuLink
                                key={item.name}
                                href={item.href}
                                isActive={router.pathname.startsWith(item.href)}
                              >
                                {item.name}
                              </MenuLink>
                            ))}
                        </div>
                      ) : (
                        <div className="mx-auto ml-10 flex items-baseline space-x-3">
                          {homepageNavigation.map((item) => (
                            <MenuLink
                              key={item.name}
                              href={item.href}
                              newTab={item.newTab}
                              isActive={router.pathname.startsWith(item.href)}
                            >
                              {item.name}
                            </MenuLink>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={clsx(isLg ? "block" : "hidden")}>
                    <div className="ml-4 flex items-center md:ml-6">
                      {
                        // Check if router.pathname includes and of the strings "/preise" "/upgrade" "/warteliste
                        !["/", "/preise", "/upgrade", "/warteliste"].includes(
                          router.pathname
                        ) && <UpgradeButton className="mr-4 self-center" />
                      }
                      {user && router.pathname === "/" && (
                        <div className="mr-4">
                          <Button
                            size="small"
                            className="whitespace-nowrap"
                            onClick={() => router.push("/start")}
                          >
                            App starten
                          </Button>
                        </div>
                      )}
                      {user && router.pathname !== "/" && (
                        <div className="mr-4">
                          <Annotation
                            content={
                              <div className="py-1">
                                <span className="mr-2">Shortcut:</span>
                                <kbd className="keyboard-button">
                                  {/* Change symbol based on os */}
                                  {macDetected ? "⌘" : "Ctrl"}
                                </kbd>{" "}
                                + <kbd className="keyboard-button">K</kbd>
                              </div>
                            }
                            placement="bottom"
                          >
                            <SearchButton />
                          </Annotation>
                        </div>
                      )}
                      <ThemeSwitcher />
                      {user ? (
                        <>
                          {/* <div className="ml-3">
                            <BellButton />
                          </div> */}

                          {/* Profile dropdown */}
                          <Menu as="div" className="relative">
                            <div>
                              <Menu.Button
                                className={clsx(
                                  user?.photoURL
                                    ? "flex max-w-xs items-center"
                                    : "mt-1 p-1",
                                  "btn-text-default rounded-full text-sm"
                                )}
                              >
                                <span className="sr-only">
                                  Nutzermenu öffnen
                                </span>
                                {user?.photoURL ? (
                                  <img
                                    className="h-8 w-8 rounded-full"
                                    src={user?.photoURL}
                                    alt="User profile"
                                  />
                                ) : (
                                  <UserIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:ring-gray-700">
                                {!["/upgrade", "/preise"].includes(
                                  router.pathname
                                ) && (
                                  <UpgradeButton className="px-2 py-1 pb-2" />
                                )}
                                {userNavigation.map((item, i) => {
                                  if (item.name === "divider") {
                                    return (
                                      <hr
                                        className="border-slate-200 dark:border-slate-600"
                                        key={`${item.name}-${i}`}
                                      />
                                    );
                                  }

                                  return (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <Link
                                          href={item.href}
                                          {...(item.newTab && {
                                            target: "_blank",
                                          })}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 dark:bg-gray-700 dark:text-white"
                                              : "",
                                            item.highlighted
                                              ? "font-semibold text-primary-default"
                                              : "text-gray-700 dark:text-slate-200",
                                            "block px-4 py-2 text-sm "
                                          )}
                                        >
                                          {item.name}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  );
                                })}
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={async () => {
                                        const { signOut } = await import(
                                          "firebase/auth"
                                        );
                                        await signOut(auth);
                                      }}
                                      aria-label="Sign out"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 dark:bg-gray-700 dark:text-white"
                                          : "",
                                        "block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-slate-200"
                                      )}
                                    >
                                      Ausloggen
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </>
                      ) : (
                        <div className="flex gap-2">
                          <MenuLink
                            href="/login"
                            isActive={router.pathname === "/login"}
                            plausbileEvent="login"
                          >
                            Einloggen
                          </MenuLink>
                          <MenuLink
                            type="primary"
                            href="/registrieren"
                            isActive={router.pathname === "/registrieren"}
                            plausbileEvent="register"
                          >
                            Registrieren
                          </MenuLink>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={clsx(
                      "-mr-2",
                      isLg ? "hidden" : "flex items-center"
                    )}
                  >
                    {/* Mobile menu button */}
                    {user && router.pathname === "/" && (
                      <div className="mr-4">
                        <Button
                          size="small"
                          className="whitespace-nowrap"
                          onClick={() => router.push("/start")}
                        >
                          App starten
                        </Button>
                      </div>
                    )}
                    {/* Add Registrieren Button to / page if use is not logged in */}
                    {!user && router.pathname === "/" && (
                      <div className="mr-4">
                        <Button
                          size="small"
                          className="whitespace-nowrap"
                          onClick={() => router.push("/registrieren")}
                        >
                          Kostenlos starten
                        </Button>
                      </div>
                    )}
                    {!["/", "/preise", "/upgrade"].includes(
                      router.pathname
                    ) && (
                      <UpgradeButton
                        onClick={() => {
                          close();
                        }}
                        className="mr-4"
                      />
                    )}
                    {/* Mobile Search Button */}
                    {user && (
                      <div>
                        <button
                          className="btn-text-default mr-3 rounded p-2"
                          onClick={() => {
                            close();
                            showSearch();
                          }}
                        >
                          <MagnifyingGlassIcon className="h-6 w-6" />
                        </button>
                      </div>
                    )}
                    {/* TODO: Add search button here for mobile view*/}
                    <Disclosure.Button
                      className={clsx(
                        "btn-text-default",
                        "inline-flex items-center justify-center rounded-md p-2"
                      )}
                    >
                      <span className="sr-only">Hauptmenu öffnen</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className={clsx(isLg && "hidden")}>
                <>
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    <ThemeSwitcherMobile />
                    {user ? (
                      <>
                        {navigation.map((item) => (
                          <MobileMenuLink
                            key={item.name}
                            name={item.name}
                            isActive={router.pathname === item.href}
                            href={item.href}
                          />
                        ))}
                        {user?.claims?.admin &&
                          adminNavigation.map((item) => (
                            <MobileMenuLink
                              key={item.name}
                              name={item.name}
                              isActive={router.pathname === item.href}
                              href={item.href}
                            />
                          ))}
                      </>
                    ) : (
                      <>
                        {homepageNavigation.map((item) => (
                          <MobileMenuLink
                            key={item.name}
                            name={item.name}
                            isActive={router.pathname === item.href}
                            href={item.href}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  <div className="border-t border-slate-300 pb-2 pt-3 dark:border-gray-700 dark:bg-gray-700/50">
                    {user ? (
                      <>
                        <div className="flex items-center px-5">
                          {/* <div className="flex-shrink-0">
                            {user?.photoURL ? (
                              <img
                                className="h-8 w-8 rounded-full"
                                src={user?.photoURL}
                                alt="User profile"
                              />
                            ) : (
                              <UserIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                          <div className="ml-3 mr-auto">
                            <div className="text-slate-00 text-sm font-medium dark:text-gray-400">
                              {user?.email || "Anonymer Nutzer"}
                            </div>
                          </div>
                          {/* <div className="ml-3">
                            <BellButton />
                          </div> */}
                        </div>
                        <div className="space-y-1 px-2">
                          {!["/", "/preise", "/upgrade"].includes(
                            router.pathname
                          ) && (
                            <UpgradeButton
                              onClick={() => {
                                close();
                              }}
                              className="px-2 py-1"
                            />
                          )}
                          {userNavigation.map((item, i) => {
                            if (item.name === "divider") {
                              return (
                                <hr
                                  className="border-slate-200 dark:border-slate-600"
                                  key={`${item.name}-${i}`}
                                />
                              );
                            }
                            return (
                              <MobileMenuLink
                                key={`${item.name}-${i}`}
                                name={item.name}
                                isActive={router.pathname === item.href}
                                href={item.href}
                              />
                            );
                          })}
                          <Disclosure.Button
                            onClick={async () => {
                              const { signOut } = await import("firebase/auth");
                              await signOut(auth);
                            }}
                            aria-label="Abmelden"
                            className={clsx(
                              "btn-text-default",
                              "block w-full rounded-md px-3 py-2 text-left text-base font-medium"
                            )}
                          >
                            Ausloggen
                          </Disclosure.Button>
                        </div>
                      </>
                    ) : (
                      <div className="space-y-1 px-2">
                        <MobileMenuLink
                          empathise
                          name="Registrieren"
                          isActive={router.pathname === "/registrieren"}
                          href={"/registrieren"}
                        />
                        <MobileMenuLink
                          name="Einloggen"
                          isActive={router.pathname === "/login"}
                          href={"/login"}
                        />
                      </div>
                    )}
                  </div>
                </>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main
          id="scroll-nav-container"
          style={{ height: mainHeight }}
          ref={mainRef}
          className="flex-1 overflow-auto bg-slate-50 dark:bg-gray-900"
        >
          {children}
        </main>
        <Toaster />
        <ConnectedModal />
        <UpgradeModal />
        <LinkPeek />
        <ContentFeedbackModal />
        <SearchPalette />
        <OnboardingTrialModal />
        {/* <TrialOfferOverlay /> */}
      </div>
    </>
  );
}

const MobileMenuLink = ({
  name,
  href,
  isActive,
  empathise,
}: {
  name: string;
  href: string;
  isActive: boolean;
  empathise?: boolean;
}) => {
  return (
    <Disclosure.Button
      key={name}
      as={Link}
      href={href}
      className={classNames(
        isActive ? "btn-text-default-active" : "btn-text-default",
        "block rounded-md px-3 py-2 text-base font-medium",
        empathise ? "border border-orange-500" : ""
      )}
      aria-current={isActive ? "page" : undefined}
    >
      {name}
    </Disclosure.Button>
  );
};

const ThemeSwitcherMobile = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <Disclosure.Button
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
      className={clsx(
        "btn-text-default",
        "flex w-full items-center justify-end rounded-md px-3 py-2 text-base font-medium "
      )}
    >
      <span className="sr-only">Theme wechseln</span>
      <span className="mr-2">
        Theme ändern: {theme === "dark" ? "Hell" : "Dunkel"}
      </span>
      {theme === "dark" ? (
        <SunIcon className="h-5 w-5" aria-hidden="true" />
      ) : (
        <MoonIcon className="h-5 w-5" aria-hidden="true" />
      )}
    </Disclosure.Button>
  );
};

const BellButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={clsx("btn-text-default", "rounded-full p-1")}
    >
      <span className="sr-only">Mitteilungen anzeigen</span>
      <BellIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};

export default NavigationContainer;
