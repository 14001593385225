import clsx from "clsx";

const SimpleStep = ({
  title,
  description,
  border,
  size,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  border?: boolean;
  size?: "large" | "small";
}) => (
  <div className="relative">
    {border ? (
      <div
        className="absolute top-6 left-3 -ml-px h-full w-0.5 bg-gradient-to-b from-slate-300/0 via-slate-300/50 to-slate-300 dark:from-slate-600/0 dark:via-slate-600/50 dark:to-slate-600"
        aria-hidden="true"
      />
    ) : null}
    <div className="group relative flex items-start">
      <span className="flex h-9 items-center" aria-hidden="true">
        <span className="relative  flex h-6 w-6 items-center justify-center rounded-full border-2 border-orange-500 bg-white dark:bg-slate-800">
          <span className="h-2 w-2 rounded-full bg-orange-500" />
        </span>
      </span>
      <span className="ml-4 flex min-w-0 flex-col items-start">
        <span
          className={clsx(
            "text-slate-900 dark:text-slate-100",
            size === "large"
              ? "text-sm font-semibold sm:text-base"
              : "text-sm font-semibold"
          )}
        >
          {title}
        </span>
        <span className="text-sm text-slate-500 dark:text-slate-300">
          {description}
        </span>
      </span>
    </div>
  </div>
);

export default SimpleStep;
