import { resolveValue, Toaster as ReactHotToaster } from "react-hot-toast";

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";

function Toaster() {
  return (
    <ReactHotToaster position={"top-right"}>
      {(t) => {
        return (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } text-gray-800 dark:text-white`}
          >
            {(["success", "error", "blank", "custom"].includes(t.type) && (
              <div className="mx-auto flex w-80 max-w-sm overflow-hidden rounded-lg border border-slate-200 bg-white shadow-md shadow-slate-200/50 dark:border-slate-700 dark:shadow-slate-800/50 sm:w-96">
                <div
                  className={clsx(
                    "flex w-[60px] items-center justify-center",
                    t.type === "success" && "bg-lime-200 text-lime-600",
                    t.type === "blank" && "bg-slate-200 text-slate-600",
                    t.type === "error" && "bg-red-200 text-red-600"
                  )}
                >
                  {t.icon ? (
                    t.icon
                  ) : t.type === "success" ? (
                    <CheckCircleIcon className="h-6 w-6" />
                  ) : t.type === "blank" ? (
                    <ExclamationCircleIcon className="h-6 w-6" />
                  ) : t.type === "error" ? (
                    <ExclamationCircleIcon className="h-6 w-6" />
                  ) : null}
                </div>

                <div className="flex-1 bg-white px-4 py-3 dark:bg-gray-800">
                  <div
                    className={clsx(
                      "mb-1 font-semibold leading-5",
                      t.type === "success" &&
                        "text-lime-500 dark:text-lime-400",
                      t.type === "blank" && "text-slate-600 dark:text-white",
                      t.type === "error" && "text-red-500"
                    )}
                  >
                    {t.type === "success" && <span>Super!</span>}
                    {/* {t.type === "blank" && <span>Hinweis</span>} */}
                    {t.type === "error" && <span>Fehler</span>}
                  </div>
                  <div className="text-sm leading-5">
                    {resolveValue(t.message, t)}
                  </div>
                </div>
              </div>
            )) ||
              resolveValue(t.message, t)}
          </div>
        );
      }}
    </ReactHotToaster>
  );
}

export const ToastHeader = ({ children }) => {
  return (
    <span className="mb-2 text-base font-semibold leading-5 text-slate-700 dark:text-white">
      {children}
    </span>
  );
};

export const ToastBody = ({ children }) => {
  return (
    <div className="text-sm text-gray-700 dark:text-white">{children}</div>
  );
};

export default Toaster;
