import { tiers } from "@components/app/PricingPage/pricingTiers";

export const getTrialPeriodDays = (priceId?: string): number => {
  if (!priceId) {
    // Return the maximum trial period available
    return Math.max(
      ...tiers
        .map((tier) => [
          tier.price?.monthly?.trialPeriodDays || 0,
          tier.price?.annually?.trialPeriodDays || 0,
        ])
        .flat()
    );
  }

  // Find the specific trial period for the given price ID
  for (const tier of tiers) {
    if (tier.price?.monthly?.stripePriceId === priceId) {
      return tier.price.monthly.trialPeriodDays || 0;
    }
    if (tier.price?.annually?.stripePriceId === priceId) {
      return tier.price.annually.trialPeriodDays || 0;
    }
  }

  return 0;
};

export const getReminderDay = (trialDays: number): number => {
  // Send reminder 2 days before trial ends
  return Math.max(trialDays - 2, 1);
};

export const getTrialPeriodMessage = (priceId?: string): string => {
  const days = getTrialPeriodDays(priceId);
  if (!priceId) {
    return `Bis zu ${days} Tage`;
  }
  return `${days} Tage`;
};
