import { NextRouter } from "next/router";
import toast from "react-hot-toast";
import { firestore } from "@utils/firebase/firebase-init";
import { ExtendedFirebaseUser } from "@types";
import { tiers } from "@components/app/PricingPage/pricingTiers";

export const startStripeSubscribePage = async (
  user: ExtendedFirebaseUser,
  publicPrice: string,
  router: NextRouter,
  setLoading: (boolean) => void,
  pageToast?: typeof toast,
  coupon?: string
) => {
  if (!user || !publicPrice) return;

  try {
    setLoading(true);

    // Find the tier and interval that matches this price ID
    let trialPeriodDays: number | undefined;
    for (const tier of tiers) {
      if (
        tier.price?.monthly.stripePriceId === publicPrice ||
        tier.price?.monthly.stripePriceIdDev === publicPrice
      ) {
        trialPeriodDays = tier.price.monthly.trialPeriodDays;
        break;
      }
      if (
        tier.price?.annually.stripePriceId === publicPrice ||
        tier.price?.annually.stripePriceIdDev === publicPrice
      ) {
        trialPeriodDays = tier.price.annually.trialPeriodDays;
        break;
      }
    }

    const { doc, collection, addDoc, onSnapshot } = await import(
      "firebase/firestore"
    );

    if (!firestore) return;
    const customerRef = doc(collection(firestore, "customers"), user.uid);

    const newCheckoutSessionRef = await addDoc(
      // This function now uses the custom checkout session, not the stripe firebase extension
      collection(customerRef, "custom_checkout_sessions"),
      {
        price: publicPrice,
        locale: "de",
        allow_promotion_codes: true,
        enable_trial: user.subscription?.trialEligible,
        trial_period_days: trialPeriodDays || 0,
        success_url: window.location.origin + router.pathname + "?success=true",
        cancel_url: window.location.origin + router.pathname + "?canceled=true",
        // TODO: Add new terms and conditions
        // consent_collection: {
        //   terms_of_service: "required",
        // },
        ...(coupon && { coupon }),
      }
    );

    // Wait for the CheckoutSession to get attached by the extension
    const unsubscribe = onSnapshot(newCheckoutSessionRef, (doc) => {
      const { error, url } = doc.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        console.error(`An error occurred: ${error.message}`);
        pageToast &&
          pageToast.error(
            "Es ist ein Fehler aufgetreten. Bitte versuche es später nochmal.",
            {
              duration: 4000,
            }
          );
        unsubscribe();
        setLoading(false);
      }

      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        router.push(url);
        unsubscribe();
      }
    });
  } catch (error) {
    console.log("Error", error);
    setLoading(false);
  }
};
