import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import toast from "react-hot-toast";
import { CheckIcon } from "@heroicons/react/20/solid";

import {
  CursorArrowRippleIcon,
  FingerPrintIcon,
  FunnelIcon,
} from "@heroicons/react/24/outline";
import {
  LockOpenIcon,
  StarIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { startStripeSubscribePage } from "@utils/startStripeSubscribePage";
import { RadioGroup } from "@headlessui/react";

import FeatureBox from "./FeatureBox";
import TrialSteps from "./TrialSteps";
import Modal from "@components/shared/Modal";
import { YoutubeEmbed } from "@components/shared/YoutubeEmbed";
import SimpleStep from "@components/shared/SimpleStep";
import { contentInfo } from "@components/homepage/Features3";
import Button from "@components/shared/Button";

import { PricingTier } from "../PricingPage/pricingTiers";
import { stepConfig } from "../stepConfig";
import useStore from "@hooks/useStore";
import { updateUserSettings } from "@utils/firebase/updateUserSettings";

import { useTrack } from "@hooks/useTrack";
import { extendTier } from "@utils/extendTier";
import { getTrialPeriodMessage } from "@utils/trialUtils";

// Keep trial steps to 14 days for now
const trialSteps: {
  id: number;
  title: string;
  description: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}[] = [
  {
    id: 1,
    title: "Heute",
    description:
      "Starte deinen vollen Zugriff auf alle Inhalte und Funktionen von Jurahilfe.de.",
    icon: LockOpenIcon,
  },
  {
    id: 2,
    title: "In 12 Tagen",
    description:
      "Wir senden dir eine Erinnerungsmail, dass die Testphase endet.",
    icon: ExclamationCircleIcon,
  },
  {
    id: 3,
    title: "In 14 Tagen",
    description: "Heute erfolgt die Abbuchung, kündige jederzeit vorher.",
    icon: StarIcon,
  },
];

const DismissButton = ({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) => (
  <button
    onClick={onClick}
    className={`text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 ${className}`}
  >
    Nein, danke
  </button>
);

const TrialOfferModal = () => {
  const router = useRouter();
  const track = useTrack();
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState<"annually" | "monthly">("annually");

  const { user } = useStore((state) => ({
    user: state.user,
  }));

  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(1);

  const [tier, setTier] = useState<PricingTier | null>(null);

  useEffect(() => {
    if (showModal) {
      extendTier("tier-pro-plus", user).then((tier) => setTier(tier));
    }
  }, [user, showModal]);

  const selectedPrice = useMemo(() => {
    return tier?.price?.[interval].stripePriceId; // We can directly use the stripePriceId, since it is already set based on the NODE_ENV in extendTierData
  }, [interval, tier]);

  useEffect(() => {
    if (
      user &&
      // !user.subscription?.trialEligible === false && // user is eligible for trial
      !user.settings?.onboarding?.onboardingDismissed && // user has not seen the trial offer
      !user.subscription?.status && // user is not subscribed
      router.pathname !== "/upgrade" && // user is not on the upgrade page
      router.pathname !== "/preise" // user is not on the pricing page
    ) {
      setShowModal(true);
      track("TrialOfferSeen");
    }
  }, [user, router.pathname, track]);

  const handleDismiss = useCallback(() => {
    if (user) {
      updateUserSettings(user.uid, "onboarding", {
        onboardingDismissed: true, // Use the previous onboarding modal value
        trialOfferSeen: true, // Also set the new trial value
      });
      track("TrialOfferDismissed");
    }
    setShowModal(false);
  }, [user]);

  // No need to call handleDismiss, as the user will be redirected to the upgrade page and when they return, the requirements will not be met anymore (user is already subscribed)
  const handleStartTrial = useCallback(async () => {
    if (user.subscription?.status === "pro") {
      toast.success("Du bist bereits Jurahilfe Pro Nutzer.", {
        duration: 5000,
      });
      handleDismiss();
      return;
    }

    // if (!user.subscription?.trialEligible) {
    //   // Double check if the user has already completed the trial
    //   toast.error("Du hast den kostenlosen Test bereits abgeschlossen.", {
    //     duration: 5000,
    //   });
    //   handleDismiss();
    //   return;
    // }

    setLoading(true);
    try {
      startStripeSubscribePage(user, selectedPrice, router, setLoading, toast);
      track("TrialOfferClicked");
    } catch (error) {
      console.error("Error starting trial:", error);
    }
  }, [user, router, selectedPrice, handleDismiss, track]);

  const { title, content, subtitle } = useMemo(() => {
    switch (step) {
      case 1:
        return {
          title: "Starte dein interaktives Jura-Training!",
          content: (
            <div className="relative h-full w-full flex-grow">
              <YoutubeEmbed
                className="w-full"
                embedId="60N8I-Lt5Yc"
                embedSettings={{
                  cc_load_policy: true,
                  mute: false,
                }}
              />
            </div>
          ),
        };
      case 2:
        return {
          title: "Unser Ziel",
          subtitle:
            "Eine moderne Prüfungsvorbereitung für mehr Verständnis und bessere Noten.",
          content: (
            <div>
              <div className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-3">
                <FeatureBox
                  title="Kompakt"
                  description="Alles relevante Wissen vom 1. Semester bis zu den Examina, komprimiert und intuitiv aufbereitet."
                  Icon={FunnelIcon}
                  pro="Nur wirklich relevantes Wissen auswendig lernen spart Zeit: Für Fallübungen oder Erholung."
                />
                <FeatureBox
                  title="Verlinkt"
                  description="Alles ist modular und mit allem verlinkt. Klick auf einen Rechtsbegriff und lies Definition und Begleitwissen."
                  Icon={FingerPrintIcon}
                  pro="Fördert Systemverständnis und vermeidet doppeltes Lernen"
                />
                <FeatureBox
                  title="Interaktiv"
                  description="In diesem intensiven Lernprozess konsumierst du nicht passiv, sondern wirst aktiv gefördert."
                  Icon={CursorArrowRippleIcon}
                  pro="Nachhaltig einprägsames und motivierendes Lernen"
                />
              </div>
            </div>
          ),
        };
      case 3:
        return {
          title: "Vierstufiges Lernsystem für strukturiertes Lernen",
          subtitle: "Meistere den Stoff Schritt-für-Schritt:",
          content: (
            <div className="-mt-2 space-y-3">
              {Object.entries(stepConfig).map(([key, step]) => (
                <SimpleStep
                  size="large"
                  key={key}
                  title={
                    <span>
                      Stufe {key}:{" "}
                      <span className="text-orange-500">{step.title}</span>
                    </span>
                  }
                  description={step.introduction.abstract}
                  border={key !== "4"}
                />
              ))}
            </div>
          ),
        };
      case 4:
        return {
          title: "Kompakte Inhalte, mit interaktiven Querverweisen",
          subtitle:
            "Alles Wissen vom 1. Semester bis zum Examen, komprimiert in einer App. Intelligente Querverlinkungen fördern dein Systemverständnis und vermeiden doppeltes Lernen.",
          content: (
            <div className="grid w-full grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-6">
              {contentInfo.map((content, idx) => (
                <div
                  key={idx}
                  className={clsx(
                    "col-span-2",
                    idx === contentInfo.length - 1 ||
                      idx === contentInfo.length - 2
                      ? "sm:col-span-3"
                      : ""
                  )}
                >
                  <FeatureBox
                    title={content.name}
                    Icon={content.icon}
                    description={content.content}
                    highlighted={[0, 1, 2].includes(idx)}
                  />
                </div>
              ))}
            </div>
          ),
        };
      case 5:
        return {
          title: "Voller Zugang – Bis zu 14 Tage kostenlos und risikofrei",
          subtitle:
            "Starte sofort mit unbegrenztem Zugang zu all unseren Funktionen. Wir erinnern dich, bevor dein Test endet – völlig risikofrei!",
          content: (
            <div className="flex h-full w-full flex-col justify-start sm:mt-4">
              <div className="mb-5 sm:-mt-4 lg:mb-10">
                <TrialSteps trialSteps={trialSteps} />
              </div>
            </div>
          ),
        };
      case 6:
        return {
          title: "Nur noch ein Schritt zu vollem Zugang!",
          subtitle: (
            <span className="text-gray-600 dark:text-gray-300">
              <b className="text-premium font-semibold">Bis zu 14 Tage lang</b>{" "}
              alle Vorteile von Jurahilfe.de mit{" "}
              <b className="text-premium">Jurahilfe Pro Plus</b> -{" "}
              <b className="text-premium">kostenlos</b> und{" "}
              <b className="text-premium">ohne Risiko</b> nutzen.
            </span>
          ),
          content: (
            <div>
              <div className="flex h-full w-full flex-col justify-start">
                <RadioGroup
                  value={interval}
                  onChange={setInterval}
                  className="w-full"
                >
                  <RadioGroup.Label className="sr-only">
                    Abo-Intervall wählen
                  </RadioGroup.Label>
                  <div className="grid w-full gap-3 px-1 sm:grid-cols-2 sm:px-0">
                    <IntervalSelectorButton
                      interval="annually"
                      setInterval={setInterval}
                      tier={tier}
                    />
                    <IntervalSelectorButton
                      interval="monthly"
                      setInterval={setInterval}
                      tier={tier}
                    />
                  </div>
                </RadioGroup>
              </div>
              <div className="mx-3 mt-3.5 mb-2 flex flex-wrap items-center justify-start gap-x-4 gap-y-2 text-xs text-gray-600 dark:text-gray-300 sm:justify-center sm:text-sm">
                <span className="flex items-center">
                  <CheckIcon className="mt-0.5 mr-1 h-4 w-4" />
                  <span>
                    <span className="text-premium font-semibold">
                      {getTrialPeriodMessage(selectedPrice)}
                    </span>{" "}
                    lang keine Zahlungen fällig
                  </span>
                </span>
                <span className="flex items-center">
                  <CheckIcon className="mt-0.5 mr-1 h-4 w-4" />
                  <span>Jederzeit kündbar</span>
                </span>{" "}
                <span className="flex items-center">
                  <CheckIcon className="mt-0.5 mr-1 h-4 w-4" />
                  <span>Erinnerung vor Ablauf der Testphase</span>
                </span>
              </div>
            </div>
          ),
        };
    }
  }, [step, tier, interval, selectedPrice]);

  return (
    <Modal
      open={showModal}
      type="upgrade"
      setClose={handleDismiss}
      title={title}
      size="large"
      closeOnOutsideClick={false}
      showCloseButton={false}
      confirmButtonText={step === 6 ? "Testphase starten" : undefined} // Not needed, as the button is replaced by the custom footer
      loading={loading}
      closeOnConfirm={false}
      onConfirm={handleStartTrial}
      multiStepConfig={{
        enabled: true,
        currentStep: step,
        totalSteps: 6,
        onNextStep: () => {
          if (step !== 6) {
            setStep(step + 1);
          }
        },
        onPreviousStep: () => setStep(step - 1),
        nextButtonText: "Weiter",
        previousButtonText: "Zurück",
      }}
      position="fullscreen"
      customFooter={
        step === 6 ? (
          <div className="mt-4 flex w-full flex-col items-center justify-between gap-y-2">
            <Button type="upgrade" onClick={handleStartTrial} loading={loading}>
              {getTrialPeriodMessage(selectedPrice)} kostenlos testen
            </Button>
            <DismissButton onClick={handleDismiss} className="mt-2" />
          </div>
        ) : undefined
      }
    >
      <div className="mx-auto flex max-w-2xl flex-col items-center justify-start ">
        {subtitle && (
          <div className="mb-5 w-full px-1 text-left text-sm text-slate-600 dark:text-slate-300 sm:mb-3 sm:px-0 sm:text-base">
            {subtitle}
          </div>
        )}
        <div className="h-full w-full sm:mt-4">{content}</div>
      </div>
    </Modal>
  );
};

export default TrialOfferModal;

const IntervalSelectorButton = ({
  interval,
  tier,
}: {
  interval: "monthly" | "annually";
  setInterval: (interval: "monthly" | "annually") => void;
  tier: PricingTier;
}) => {
  return (
    <RadioGroup.Option
      value={interval}
      className={({ checked }) =>
        `relative block cursor-pointer rounded-lg border px-5 py-4 shadow-sm focus:outline-none ${
          checked
            ? "border-orange-500 bg-gray-700/20 ring-1 ring-orange-500"
            : "border-gray-300 dark:border-gray-700"
        }`
      }
    >
      <div className="flex items-start justify-between text-sm">
        <div>
          <RadioGroup.Label
            as="p"
            className="font-medium text-gray-900 dark:text-gray-100"
          >
            {tier?.price?.[interval].displayInfo?.name}
          </RadioGroup.Label>
          <RadioGroup.Description
            as="span"
            className="text-gray-500 dark:text-gray-400"
          >
            {tier?.price?.[interval].displayInfo?.pricePerInterval} /{" "}
            {tier?.price?.[interval].displayInfo?.interval}
          </RadioGroup.Description>
        </div>
        <div className="flex h-full shrink-0 flex-col items-end justify-end">
          <div>
            {tier?.price?.[interval].displayInfo?.priceMonth}
            /Monat
          </div>
          <div className="font-semibold text-orange-500">
            {interval === "annually" && "2 Monate gratis"}
          </div>
        </div>
      </div>
    </RadioGroup.Option>
  );
};
